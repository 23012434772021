import styled from 'styled-components';

export const SearchListElement = styled.div`
  .title,
  .no-results {
    margin: 60px 0 38px 0;
    font-size: 36px;
    font-weight: 600;
    line-height: 41px;
    color: var(--theme-deep-purple);
  }

  .no-results {
    text-align: center;
  }
  
  .categories {
    display: flex;
    margin: 0 0 30px 0;
    border-bottom: 1px solid var(--theme-dark-gray);
    padding: 0 0 15px 0;
    list-style: none;
    
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      max-width: 170px;
      margin-right: 30px;
      border-bottom: 2px solid transparent;
      padding: 0 30px 12px 25px;
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 1.25px;
      color: var(--theme-deep-purple);
      transition: .3s all ease-in-out;
      
      &:hover {
        border-color: var(--theme-purple);
        color: var(--theme-purple);
        cursor: pointer;
      }
      
      &--active {
        border-color: var(--theme-purple);
      }
      
      &__amount {
        position: absolute;
        top: 13px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        border-radius: 50%;
        font-size: 14px;
        color: var(--white);
        background-color: var(--theme-purple);
      }
    }
  }
  
  .list {
    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid var(--theme-dark-gray);
      
      &.is-hidden {
        display: none;
      }
      
      &__left {
        display: flex;
        align-items: flex-start;
      }
      
      &__right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        min-width: 170px;
      }
      
      &__img {
        display: block;
        margin-right: 40px;
        
        &__link {
          display: block;
          text-decoration: none;
        }
      }
      
      &__content {
        display: flex;
        flex-direction: column;      
        
      }
      
      &__title {
        margin: 0 0 24px 0;
        
        &__link {
          font-size: 28px;
          font-weight: 700;
          line-height: 34px;
          text-transform: uppercase; 
          text-decoration: none;
          color: var(--theme-deep-purple);
          transition: .3s all ease-in-out;
          
          &:hover {
            color: var(--theme-blue);
          }
        }
      }
      
      &__description {
        margin: 0 0 25px 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 1.25px;
      }
      
      &__author {
        display: flex;
        align-items: flex-start;
              
        &__img {
          --dimensions: 50px;
          
          width: var(--dimensions);
          height: var(--dimensions);
          margin-right: 15px;
          border-radius: 50%;
        }
        
        &__meta {
          display: flex;
          flex-direction: column;
        }
        
        &__name {
          font-size: 16px;
          font-weight: 400;
          line-height: 29px;
          letter-spacing: 1.2px;
          color: var(--theme-dark-gray);
        }
        
        &__comments {
          font-size: 14px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 1.2px;
          color: var(--theme-dark-gray);
        }
      }
      
      &__date {
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 1.2px;
        color: var(--theme-dark-gray);
      }
      
      &__btn {
        --dimensions: 52px;
        
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--dimensions);
        height: var(--dimensions);
        border: 2px solid var(--theme-blue);
        border-radius: 50%;
        transition: .3s all ease-in-out;
        
        &:hover {
          transform: rotate(1turn);
        }
        
        &__arrow {
          --dimensions: 20px;
          
          width: var(--dimensions);
          height: var(--dimensions);
        }
      }
    }
  } 
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1440px) {
    .title {
      margin-top: 40px;
    }
  }
  
  @media (max-width: 1280px) {
    .categories {
      &__item {
        margin-right: 20px;
      }
    }
  
    .list {
      &__item {
        &__img {
          margin-right: 20px;
        }
      
        &__title,
        &__description {
          margin-bottom: 10px;
        }
      
        &__right {
          min-width: 120px;
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    .categories {
      &__item {
        max-width: 155px;
        padding: 0 25px 12px 20px;
        font-size: 14px;
        line-height: 20px;
        
        &__amount {
          top: 10px;
        }
      }
    }
  
    .list {
      &__item {
        &__img {
          width: 300px;
        }
        
        &__right {
          min-width: 100px;
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    .categories {
      position: relative;
      overflow-x: auto;
      
      &__item {
        display: flex;
        justify-content: center;
        flex-basis: 130px;
        flex-shrink: 0;
        flex-grow: 0;
        padding-left: 15px;
        line-height: 16px;
        
        &__amount {
          top: 6px;
        }
      }
    }
  
    .list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -42px;
        width: 100%;
        height: 10px;
        background: white;
        z-index: 3;
      }
    
      &__item {
        flex-direction: column;
      }
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 26px;
      line-height: 32px;
    }
    
    .categories {
      &__item {
        flex-basis: 115px;
        padding-left: 0;
        padding-right: 20px;
      }
    }
  }
`;