import React, { useContext, useEffect, useState } from 'react';
import { graphql, useStaticQuery }                from 'gatsby';

// import context
import { GlobalStateContext } from '../context/GlobalContextProvider';

// import styles
import { SearchPageWrapper } from '../styles/BlogSearch';

// import components
import Seo        from '../components/common/Seo';
import PageMeta   from '../components/elements/PageMeta/index';
import Search     from '../components/elements/Search';
import SearchList from '../components/elements/Search/SearchList';

const SearchPage = ( props ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          metas {
            data {
              description
              title
            }
          }
          search_texts {
            search_results
          }
        }
      }
    }
  `);

  // get the app global state
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [metaTags, setMetaTags] = useState({
    metaTitle: '',
    metaDescription: '',
    searchResults: ''
  });

  const { metaTitle, metaDescription, searchResults } = metaTags;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { metas, search_texts } = node;
      const currentMetas  = metas[1].data[0];
      const currentSearch = search_texts[1].search_results;
      const { description, title } = currentMetas;

      setMetaTags({
        metaTitle: title,
        metaDescription: description,
        searchResults: currentSearch
      });
    });
  }, [data] );

  const results = {
    searchResults: props.location.state !== null && props.location.state !== undefined ? props.location.state.searchResults : [],
    searchValue: props.location.state !== null && props.location.state !== undefined ? props.location.state.searchValue : ''
  };

  return (
    <>
      <Seo title={ metaTitle } description={ metaDescription } lang={ defaultLang } />
      <PageMeta type="single" text={ searchResults } />
      <SearchPageWrapper className="container">
        <Search className="search-page" />
        <SearchList results={ results } currentLang={ defaultLang } />
      </SearchPageWrapper>
    </>
  )
};

export default SearchPage;