import React, { memo, useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link }    from 'gatsby';
import PropTypes                            from 'prop-types';

// import constants
import { BACKEND_URL } from '../../../constants';

// import helpers
import { getCategoryName, useWindowSize } from '../../../helpers';

// import styles
import { SearchListElement } from '../../../styles/elements/Search/SearchList';

// import components
import ArrowRegularIcon from '../icons/ArrowRegularIcon';
import PostPreview      from '../Post/PostPreview';

const SearchList = memo( ( { results, currentLang } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          search_texts {
            no_results
            search_for_text
          }
          categories_text {
            all_text
          }
        }
      }
    }
  `);

  // get props
  const { searchResults, searchValue } = results;

  // define the default component state
  const [texts, setTexts] = useState({
    noResultsText: '',
    searchForText: '',
    allForCategories: ''
  });

  const { noResultsText, searchForText, allForCategories } = texts;

  // define hooks
  const { width } = useWindowSize();

  const resultsLen = searchResults !== undefined && searchResults.length > 0 ? searchResults[1].posts.length : 0;
  const resultCategories = searchResults !== undefined && searchResults.length > 0 ? searchResults[0].categories : {};
  const categoriesNames  = Object.keys( resultCategories );
  const getSearhcValue = searchValue!== undefined ? searchValue : '';

  console.log( categoriesNames );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { search_texts, categories_text } = node;
      let orderNumber;

      switch ( currentLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const currentSearch = search_texts[orderNumber];
      const currentCategories = categories_text[orderNumber].all_text;
      const { no_results, search_for_text } = currentSearch;

      setTexts({
        noResultsText: no_results,
        searchForText: search_for_text,
        allForCategories: currentCategories
      });
    });
  }, [data, currentLang] );

  /**
   * Filter by category
   *
   * @param e | Event
   * @param category | string
   */
  const handleClickCategory = ( e, category ) => {
    const searchedPosts    = document.getElementsByClassName( 'search-post-item' );
    const filterCategories = document.getElementsByClassName( 'categories__item' );

    for ( let cat of filterCategories ) {
      cat.classList.remove( 'categories__item--active' );
    }

    e.currentTarget.classList.add( 'categories__item--active' );

    if ( category === 'All' ) {
      for ( let post of searchedPosts ) {
        post.classList.remove( 'is-hidden' );
      }
    } else {
      for ( let post of searchedPosts ) {
        if ( post.dataset.category !== category ) {
          post.classList.add( 'is-hidden' );
        } else {
          post.classList.remove( 'is-hidden' );
        }
      }
    }
  };

  /**
   * Return
   */
  if ( resultsLen > 0 ) {
    return (
      <SearchListElement>
        <h2 className="title">{ `${ resultsLen } ${ searchForText } "${ getSearhcValue }"` }</h2>

        <ul className="categories">
          <li className="categories__item categories__item--active"
              role="presentation"
              onClick={ ( e ) => handleClickCategory( e, 'All' ) }>
            { allForCategories } <span className="categories__item__amount">{ resultsLen }</span>
          </li>
          { categoriesNames.map( category => {
            const categoryName = getCategoryName( category );
            const postsCount   = resultCategories[`${ category }`].length;
            const categoryNameHtml = `${ categoryName } <span class="categories__item__amount">${ postsCount }</span>`;

            return <li className="categories__item"
                       key={ category }
                       role="presentation"
                       onClick={ ( e ) => handleClickCategory( e, category ) }
                       dangerouslySetInnerHTML={ { __html: categoryNameHtml } } />;
          } ) }
        </ul>

        <div className="list">
          { searchResults[1].posts.map( result => {
            const {
              title, slug, image_regular, image_regular_alt, short_description, comments_text_multiple,
              comments_text_single, total_comments, date, image_regular_increased, image_regular_increased_alt,
              category,
              author: { image: authorImg, image_alt: authorAlt, name: authorName }
            } = result;

            const commentsText = total_comments === 1 ? comments_text_single : comments_text_multiple;
            const postUrl = currentLang === 'de' ? `/${ slug }` : `/en/${ slug }`;

            if ( width > 991 ) {
              return (
                <div className="list__item search-post-item"
                     key={ slug }
                     data-category={ category }>
                  <div className="list__item__left">
                    <Link className="list__item__img__link" to={ postUrl }>
                      <img className="list__item__img"
                           src={ BACKEND_URL + image_regular }
                           alt={ image_regular_alt } />
                    </Link>
                    <div className="list__item__content">
                      <h3 className="list__item__title">
                        <Link className="list__item__title__link" to={ postUrl }>{ title }</Link>
                      </h3>
                      <p className="list__item__description">{ short_description }</p>
                      <div className="list__item__author">
                        <img className="list__item__author__img"
                             src={ BACKEND_URL + authorImg }
                             alt={ authorAlt } />
                        <div className="list__item__author__meta">
                          <span className="list__item__author__name">{ authorName }</span>
                          <span className="list__item__author__comments">{ `${ total_comments } ${ commentsText }` }</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list__item__right">
                    <span className="list__item__date">{ date }</span>
                    <Link className="list__item__btn" to={ postUrl }>
                      <ArrowRegularIcon className="list__item__btn__arrow" color="#00B7F1" />
                    </Link>
                  </div>
                </div>
              );
            } else {
              const data = {
                postRegularImage: image_regular,
                postRegularImageAlt: image_regular_alt,
                postRegularImageIncreased: image_regular_increased,
                postRegularImageIncreasedAlt: image_regular_increased_alt,
                postTitle: title,
                postDate: date,
                postCategory: category,
                postShortDescription: short_description,
                postCommentsCount: total_comments,
                postCommentsTextSingle: comments_text_single,
                postCommentsTextMultiple: comments_text_multiple,
                postSlug: slug,
                postAuthorData: { name: authorName, authorImg, image_alt: authorAlt }
              };
              return <PostPreview data={ data }
                                  key={ slug }
                                  additionalClass="search-post-item"
                                  dataAttr="category"
                                  dataAttrValue={ category } />
            }
          })}
        </div>
      </SearchListElement>
    );
  } else {
    return (
      <SearchListElement>
        <h2 className="no-results">{ `${ noResultsText } "${ getSearhcValue }"` }</h2>
      </SearchListElement>
    );
  }
});

SearchList.defaultProps = {
  results: {},
  currentLang: 'de'
};

SearchList.propTypes = {
  results: PropTypes.object,
  currentLang: PropTypes.string
};

export default SearchList;